<template>
  <div class="flex-box">
    <van-sticky>
      <title-bar2 :title="majorGroup.MajorName" @search="onReload" />
    </van-sticky>
    <div class="flex-between">
      <span>{{ majorGroup.MajorName }}</span>
      <major-tag :code="majorGroup.MajorCode"></major-tag>
    </div>
    <div class="flex-row">
      <div class="major-text">{{ majorGroup.MajorDegreeType }}</div>
      <van-icon name="arrow" size="12" color="gray" class="major-arrow" />
      <div class="major-text">{{ majorGroup.MajorBelongcategory }}</div>
      <van-icon name="arrow" size="12" color="gray" class="major-arrow" />
      <div class="major-text">{{ majorGroup.MajorBelongFirstDiscipline }}</div>
    </div>
    <van-sticky :offset-top="50">
      <school-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onReload"></school-filter>
    </van-sticky>
    <div class="school-total">院校（{{ total }}所）</div>
    <van-list class="school-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <school-item class="school-item" v-for="school in schoolList" :key="school.UniversityGuid" :title="school.UniversityName" :partition="school.UniversityPartition" :province="school.UniversityProvince" :teachtype="school.UniversityTeachType" :characteristic="school.UniversityCharacteristic" :img="schoolImg(school.UniversityCode)" @click="onItemClick(school)"></school-item>
    </van-list>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import SchoolFilter from "@/components/MT/SchoolFilter";
import SchoolItem from "@/components/MT/SchoolItem";
import MajorTag from "@/components/MT/MajorTag";
import Config from "@/api/service";

export default {
  name: "MTModule",
  components: {
    TitleBar2,
    SchoolFilter,
    SchoolItem,
    MajorTag,
  },
  data() {
    return {
      index: 0,
      total: 0,
      loading: false,
      finished: false,
      filter1: [],
      filter2: [],
      filter3: [],
      filter4: '',
      majorCode: null,
      majorGroup: {},
      schoolList: [],
    };
  },
  computed: {
    schoolImg() {
      return function (code) {
        return `${Config.school.logo}?SchoolCode=${code}`;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.majorCode = this.$route.params.majorCode;
    },
    //获取专业分组
    getMajorGroup() {
      var model = {
        MajorCode: this.majorCode
      };
      this.$axios
        .post(Config.major.groupInfo, model)
        .then((res) => {
          this.majorGroup = res.data.Data;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //重新加载列表
    onReload() {
      this.finished = false;
      this.loading = true;
      this.index = 0;
      this.schoolList = [];
      this.onLoad();
    },
    //加载当前页数据
    onLoad() {
      var model = {
        UniversityProvinces: this.filter1,
        UniversityTeachTypes: this.filter2,
        UniversityCharacteristics: this.filter3,
        UniversitySubject: this.filter4,
        MajorCode: this.majorCode,
        PageIndex: ++this.index,
        PageSize: 20,
      };
      this.$axios
        .post(Config.major.schoolList, model)
        .then((res) => {
          let data = res.data.Data;
          this.schoolList.push(...data.UniversityInfos);
          this.loading = false;
          this.total = data.TotalCount;
          if (this.index >= data.PageCount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //点击大学项
    onItemClick(university) {
      this.$router.push({
        name: "School1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: university.UniversityCode
        },
        query: {
          majorCode: this.majorCode
        }
      });
    },
  },
  mounted() {
    this.getRoutParams();
    this.getMajorGroup();
    this.onLoad();
  }
};
</script>
<style scoped>
.flex-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin: 10px 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}

.major-text {
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  font-weight: 400;
}

.major-arrow {
  margin: 0 5px;
}

.school-total {
  color: gray;
  font-size: 14px;
}

.school-list {
  margin-top: 10px;
}

.school-item {
  margin-bottom: 10px;
}
</style>