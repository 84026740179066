<template>
    <div class="container-box" v-loading="loading" element-loading-text="加载中...">
        <div class="box-left">
            <el-breadcrumb separator=">" class="breadcrumb-path">
                <el-breadcrumb-item :to="{ name: 'Major' }">查专业</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'Major', query: { majorBelongcategory: majorGroup.MajorBelongcategory } }">{{ majorGroup.MajorBelongcategory }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'Major', query: { majorBelongcategory: majorGroup.MajorBelongcategory, majorBelongFirstDiscipline: majorGroup.MajorBelongFirstDiscipline } }">{{ majorGroup.MajorBelongFirstDiscipline }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ majorGroup.MajorName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="major-name">{{ majorGroup.MajorName }}</div>
            <div class="bottom-line"></div>
            <div class="major-code">
                <span>学位类别：</span>
                <span>{{ majorGroup.MajorDegreeType }}</span>
                <span class="verticalbar"></span>
                <span>专业代码：</span>
                <span>{{ majorGroup.MajorCode }}</span>
            </div>
            <header-container title="开设院校">
                <school-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onFilterClick"></school-filter>
            </header-container>
            <div class="dark-border school-list">
                <div v-for="(school, index) in schoolList" :key="index">
                    <school-item :title="school.UniversityName" :partition="school.UniversityPartition" :province="school.UniversityProvince" :characteristic="school.UniversityCharacteristic" :img="schoolImg(school.UniversityCode)" @click="onItemClick(school)"></school-item>
                    <div v-if="index < schoolList.length - 1" class="bottom-line"></div>
                </div>
                <div class="pagination-box">
                    <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
                </div>
            </div>
        </div>
        <div class="box-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import SchoolFilter from "@/components/PC/SchoolFilter";
import SchoolItem from "@/components/PC/SchoolItem";
import SchoolHot from "@/components/PC/SchoolHot";
import HeaderContainer from "@/components/PC/HeaderContainer";
import HeaderEntry from "@/components/PC/HeaderEntry";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolFilter,
        SchoolItem,
        SchoolHot,
        HeaderEntry,
        HeaderContainer,
    },
    data() {
        return {
            loading: true,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            filter1: [],
            filter2: [],
            filter3: [],
            filter4: '',
            majorCode: null,
            majorGroup: {},
            schoolList: [],
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                return `${Config.school.logo}?SchoolCode=${code}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.majorCode = this.$route.params.majorCode;
        },
        //获取专业分组
        getMajorGroup() {
            var model = {
                MajorCode: this.majorCode
            };
            this.$axios
                .post(Config.major.groupInfo, model)
                .then((res) => {
                    this.majorGroup = res.data.Data;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取大学列表
        getUniversityInfos() {
            this.loading = true;
            var model = {
                UniversityProvinces: this.filter1,
                UniversityTeachTypes: this.filter2,
                UniversityCharacteristics: this.filter3,
                UniversitySubject: this.filter4,
                MajorCode: this.majorCode,
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
            };
            this.$axios
                .post(Config.major.schoolList, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.schoolList = data.UniversityInfos;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击大学项
        onItemClick(school) {
            this.$router.push({
                name: "School1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: school.UniversityCode
                },
                query: {
                    majorCode: this.majorCode
                }
            });
        },
        //点击搜索
        onFilterClick() {
            this.getUniversityInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getUniversityInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getMajorGroup();
        this.getUniversityInfos();
    }
};
</script>
<style scoped>
.container-box {
    display: flex;
    flex-direction: row;
}

.box-left {
    flex: 1;
}

.box-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.breadcrumb-path {
    margin-bottom: 20px;
}

.major-name {
    font-size: 28px;
    font-weight: 700;
}

.major-code {
    color: #000000A6;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.school-list {
    margin-top: 20px;
    padding: 20px;
}

.dark-border {
    border: 1px solid #e6e6e6;
}

.bottom-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}

.column-margin {
    margin: 20px 0;
}

.verticalbar {
    border-left: 1px solid #c5c5c5;
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin: 0 10px;
    top: 2px;
}

::v-deep .el-breadcrumb__inner.is-link {
    font-weight: normal;
}
</style>